<template>
  <v-dialog v-model="dialogOpen"
    content-class="my-custom-dialog"
    transition="dialog-top-transition"
    persistent
    max-width="630"
    @keydown.esc="close">
    <!-- BUG: if you put the border around the card, the bottom of the card won't be transparent -->
    <v-card tile
      color="white"
      style="border: 1px solid white">
      <v-card-title>
        <h2 class="text--secondary">Odaberi template blagajne</h2>
        <v-spacer></v-spacer>
        <v-btn color="blue"
          @click="close">
          <span style="color: white">Zatvori</span>
        </v-btn>
      </v-card-title>
      <v-card-text class="white">
        <v-container class="panel-container">
          <v-row class="pb-0 mb-0">
            <v-col class="pb-0 mb-0"
              cols="12">
              <v-select v-model="selectedTemplate"
                :items="availableTemplates"
                item-text="label"
                item-value="value"
                @change="onSelectTemplate"></v-select>
            </v-col>
          </v-row>
          <v-row v-if="selectedTemplate === 'ai-crud'"
            class="pb-0 mb-0 pt-0 mt-0">
            <v-col class="pb-0 mb-0 pt-0 mt-0"
              cols="12">
              <v-select label="Tip pretraživanja"
                v-model="selectedSearchType"
                :items="availableSearchTypes"
                item-text="label"
                item-value="value"
                @change="onSelectSearchType">
              </v-select>
              <div v-html="showExplanation()"></div>
            </v-col>
          </v-row>
          <v-row class="pt-0 mt-0"
            v-if="
              selectedTemplate === 'panel' ||
              selectedTemplate === 'ai-caffe-wide' ||
              selectedTemplate === 'bottom-up'
            ">
            <v-col class="pt-0 mt-0"
              cols="6">
              <p>Template gumbi za plaćanje</p>
              <v-radio-group row
                v-model="selectedPaymentPanel">
                <v-radio label="default"
                  value="default"
                  @change="selectPaymentPanel" />
                <v-radio label="simple"
                  value="simple"
                  @change="selectPaymentPanel" />
              </v-radio-group>
            </v-col>
            <v-col v-if="
              selectedTemplate === 'ai-caffe-wide' ||
              selectedTemplate === 'bottom-up'
            "
              class="pt-0 mt-0"
              cols="6">
              <p>Podešavanje template-a</p>
              <v-row style="margin-top: -1rem">
                <v-col cols="4">Redovi:</v-col>
                <v-col cols="8">
                  <v-slider min="1"
                    max="12"
                    v-model="panelRows"
                    thumb-label="always"
                    @change="adjustTemplate()"></v-slider>
                </v-col>
              </v-row>
              <v-row style="margin-top: -2rem">
                <v-col cols="4">Stupci:</v-col>
                <v-col cols="8">
                  <v-slider min="1"
                    max="12"
                    thumb-label="always"
                    v-model="panelCols"
                    @change="adjustTemplate()"></v-slider>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="pt-0 mt-0"
            v-if="selectedTemplate === 'ai-caffe-wide'">
            <v-col v-if="selectedTemplate === 'ai-caffe-wide'"
              class="pt-0 mt-0"
              cols="12">
              <p style="
                  font-size: 18px;
                  text-align: center;
                  padding-bottom: 15px;
                ">
                Podešavanje izgleda košarice
              </p>
              <v-row style="margin-top: -1rem">
                <table class="tableCart">
                  <tbody>
                    <tr>
                      <td>
                        <v-checkbox class="d-inline-flex"
                          color="green"
                          dense
                          style="text-align: center !important"
                          hide-details
                          @change="changeCartConfiguration()"
                          v-model="cartConfiguration.rb"></v-checkbox>
                      </td>
                      <td style="text-align: center">
                        <v-checkbox class="d-inline-flex"
                          color="green"
                          dense
                          hide-details
                          @change="changeCartConfiguration()"
                          v-model="cartConfiguration.name"
                          :readonly="true"></v-checkbox>
                      </td>

                      <td style="text-align: center">
                        <v-checkbox dense
                          class="d-inline-flex"
                          color="green"
                          hide-details
                          @change="changeCartConfiguration()"
                          v-model="cartConfiguration.quantity"
                          :readonly="true"></v-checkbox>
                      </td>
                      <td style="text-align: center">
                        <v-checkbox dense
                          class="d-inline-flex"
                          color="green"
                          hide-details
                          @change="changeCartConfiguration()"
                          v-model="cartConfiguration.unit"></v-checkbox>
                      </td>
                      <td style="text-align: center">
                        <v-checkbox dense
                          class="d-inline-flex"
                          color="green"
                          hide-details
                          @change="changeCartConfiguration()"
                          v-model="cartConfiguration.single_price"
                          :readonly="true"></v-checkbox>
                      </td>
                      <td style="text-align: center">
                        <v-checkbox dense
                          class="d-inline-flex"
                          color="green"
                          hide-details
                          @change="changeCartConfiguration()"
                          v-model="cartConfiguration.single_price_discount"></v-checkbox>
                      </td>
                      <td style="text-align: center">
                        <v-checkbox dense
                          class="d-inline-flex"
                          color="green"
                          hide-details
                          @change="changeCartConfiguration()"
                          v-model="cartConfiguration.total"
                          :readonly="true"></v-checkbox>
                      </td>
                    </tr>
                    <tr>
                      <td style="text-align: center">Rb.</td>
                      <td style="text-align: center">Naziv</td>
                      <td style="text-align: center">Količina</td>
                      <td style="text-align: center">Mj. jedinica</td>
                      <td style="text-align: center">Jed. cijena</td>
                      <td style="text-align: center">Jed. cijena s pop.</td>
                      <td style="text-align: center">Ukupno</td>
                    </tr>
                  </tbody>
                </table>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import state from '@/state'
import { df } from '@/plugins/firebase'
import EventBus from '@/plugins/event-bus'

export default {
  data () {
    return {
      dialogOpen: true,
      selectedTemplate: {},
      selectedPaymentPanel: undefined,
      panelCols: null,
      panelRows: null,
      panelColspan: null,
      availableTemplates: [
        { label: 'Aikasa', value: 'ai-crud' },
        { label: 'Old school', value: 'cash' },
        { label: 'Caffe restaurant', value: 'ai-caffe-wide' },
        { label: 'Caffe restaurant 2', value: 'panel' }

        // { label: 'components-bottom-up', value: 'bottom-up' },
      ],
      cartConfiguration: {
        rb: true,
        name: true,
        quantity: true,
        unit: true,
        single_price: true,
        single_price_discount: true,
        total: true
      },
      availableSearchTypes: [
        { label: 'Učitani artikli', value: 'load' },
        { label: 'Pretraži na upis', value: 'query' }
      ],
      selectedSearchType: undefined
    }
  },
  methods: {
    showExplanation () {
      switch (this.selectedSearchType) {
        case 'query':
          return ''
        case 'load':
          return 'Svi artikli sa cjenika učitat će se u blagajnu radi brže pretrage. <br>Ako se blagajna uspori zbog velike količine artikala, promijenite ovu postavku.'
      }
    },
    onSelectSearchType () {
      var cashReg = state.getCashRegister()
      cashReg.search_type = this.selectedSearchType

      this.saveRegisterSearchType()
      state.setCashRegister(cashReg)
    },
    async saveRegisterSearchType () {
      const me = this
      df.doc(`cash_registers/${state.getCashRegister().id}`).update({ search_type: this.selectedSearchType })
        .then(() => {
        })
        .catch(() => {
          me.hideLoader()
          me.showMsgBox({ text: 'Pogreška prilikom promjene tipa pretraživanja blagajne', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        })
    },
    async loadConfiguration () {
      const conf = await df.doc(`cash_registers/${state.getCashRegister().id}/cart_configuration/configuration`).get()

      if (conf && conf.data()) {
        this.cartConfiguration = conf.data()
      } else {
        this.cartConfiguration = {
          rb: true,
          name: true,
          quantity: true,
          unit: true,
          single_price: true,
          single_price_discount: true,
          total: true
        }
      }
    },
    close () {
      this.$emit('closeTemplatesDialog')
    },
    onSelectTemplate () {
      state.setRegisterType(this.selectedTemplate)
      this.$emit('selectTempate')
      // if (state.getRegisterType() !== 'ai-caffe-wide' && state.getRegisterType() !== 'bottom-up') {
      //   this.$emit('closeTemplatesDialog')
      // }
    },
    selectTemplate (template) {
      state.setRegisterType(template)
      this.$emit('selectTempate')
      // if (state.getRegisterType() !== 'ai-caffe-wide' && state.getRegisterType() !== 'bottom-up') {
      //   this.$emit('closeTemplatesDialog')
      // }
    },
    selectPaymentPanel () {
      this.$emit('selectPaymentPanel')
    },
    adjustTemplate () {
      this.saveTemplate()
      // console.log(this.panelRows, this.panelCols, this.panelColspan)
      state.setPanelRows(this.panelRows)
      state.setPanelCols(this.panelCols)
      state.setPanelColspan(this.panelColspan)
      EventBus.$emit('adjust-template')
    },
    changeCartConfiguration () {
      df.doc(`cash_registers/${state.getCashRegister().id}/cart_configuration/configuration`).set(this.cartConfiguration)
        .then(() => {
          console.info('Cash register update succesfull')
          EventBus.$emit('adjust-cart', this.cartConfiguration)
        })
        .catch((err) => {
          console.error('Error updating cash register: ', err)
        })
      // .finally(() => {
      // })
    },
    saveTemplate () {
      this.panelColspan = 12 / this.panelCols
      df.doc(`cash_registers/${state.getCashRegister().id}`).update({ panel_rows: this.panelRows, panel_cols: this.panelCols, panel_colspan: this.panelColspan })
        .then(() => {
          console.info('Cash register update succesfull')
        })
        .catch((err) => {
          console.error('Error updating cash register: ', err)
        })
      // .finally(() => {
      // })
    },
    async getPanelParams () {
      const register = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
      this.panelRows = register.data().panel_rows ? register.data().panel_rows : 6
      this.panelCols = register.data().panel_cols ? register.data().panel_cols : 6
      this.panelColspan = register.data().panel_colspan ? register.data().panel_colspan : 12 / this.panelCols
      state.setPanelRows(this.panelRows)
      state.setPanelCols(this.panelCols)
      state.setPanelColspan(this.panelColspan)
    }
  },
  created () {
    if (state.getRegisterType() === 'cash') {
      this.selectedTemplate = 'cash'
    } else if (state.getRegisterType() === 'panel') {
      this.selectedTemplate = 'panel'
    } else if (state.getRegisterType() === 'ai-caffe-wide') {
      this.selectedTemplate = 'ai-caffe-wide'
    } else if (state.getRegisterType() === 'bottom-up') {
      this.selectedTemplate = 'bottom-up'
    } else if (state.getRegisterType() === 'ai-crud') {
      this.selectedTemplate = 'ai-crud'
    }

    if (state.getRegisterPaymentPanel() === 'default' | state.getRegisterPaymentPanel() === '') {
      this.selectedPaymentPanel = 'default'
    } else if (state.getRegisterPaymentPanel() === 'simple') {
      this.selectedPaymentPanel = 'simple'
    }

    this.panelRows = state.getPanelRows()
    this.panelCols = state.getPanelCols()
    this.panelColspan = state.getPanelColspan()
  },

  async mounted () {
    await this.getPanelParams()
    await this.loadConfiguration()

    var cashReg = state.getCashRegister()
    this.selectedSearchType = cashReg.search_type ?? 'load'
  }
}
</script>

<style scoped>
.v-dialog__content>>>.my-custom-dialog {
  position: absolute;
  top: 50px;
}

.panel-container {
  margin-top: 1rem;
}

.tableCart {
  border: 1px solid #666666;
  table-layout: fixed;
  width: 100% !important;
  border-spacing: 0px;
}

.tableCart td {
  border: 1px solid #666666;
  width: 100%/7;
  text-align: center;
}
</style>
