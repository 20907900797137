<template>
  <v-dialog v-if="visible"
    v-model="visible"
    content-class="my-custom-dialog"
    scrollable
    persistent
    width="55%"
    style="height: 50px; z-index: 6; height: 500px !important"
    @keydown.esc="close">
    <v-form ref="editCompanyForm"
      @submit.prevent="submit"
      @input="change = true">
      <v-card tile
        color="white"
        class="pb-3"
        :class="[
          { 'mobile-card': $vuetify.breakpoint.smAndDown },
          { 'card-company': !$vuetify.breakpoint.smAndDown },
        ]">
        <v-card-title class="d-flex"
          style="
            vertical-align: middle;
            background-color: #1577da;
            height: 58px;
            color: white;
          ">
          <span class="text-h5"
            style="font-weight: bold">Firma</span>
        </v-card-title>

        <v-card-text style="padding: 0; margin: 0; height: 100% !important">
          <v-container style="height: 100% !important; overflow-y: hidden">
            <v-tabs class="pb-5"
              v-model="tab"
              @change="changeTab(tab)">
              <v-tab style="font-size: 14px !important; margin-right: 0px !important"
                v-for="oneTab of tabs"
                :key="oneTab.id">
                <v-icon v-if="oneTab.name === 'Osnovni podaci' && !basicDataValid"
                  color="red">mdi-alert-circle</v-icon>

                <v-icon v-if="oneTab.name === 'Banka' && !bankDataValid"
                  color="red">mdi-alert-circle</v-icon>

                <v-icon v-if="oneTab.name === 'Poslovni podaci' && !legalInfoValid"
                  color="red">mdi-alert-circle</v-icon>
                <v-icon class="pr-2">{{ oneTab.icon }}</v-icon>
                <span><b>{{ oneTab.name }}</b></span>
              </v-tab>
            </v-tabs>
            <v-tabs-items style="height: 100% !important"
              v-model="tab">
              <v-tab-item style="height: 100% !important">
                <v-row class="pt-5">
                  <v-col cols="6"
                    class="pt-0 pb-3 mb-0 mt-0">
                    <v-text-field class="textField"
                      dense
                      hide-details
                      outlined
                      label="Naziv"
                      v-model="company.name"
                      :rules="[rules.req]">
                    </v-text-field>
                  </v-col>
                  <v-col cols="6"
                    class="pt-0 pb-3 mb-0 mt-0">
                    <v-text-field autofocus
                      hide-details
                      dense
                      class="textField"
                      outlined
                      label="OIB"
                      id="oib"
                      v-mask="'###########'"
                      v-model="company.oib"
                      :rules="[rules.req, rules.oib]"
                      ref="oib"></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="pb-2">
                  <v-col cols="6"
                    class="pt-0 mt-0 pb-3 mb-0">
                    <v-text-field dense
                      hide-details
                      class="textField"
                      outlined
                      label="Adresa"
                      v-model="company.address"
                      :rules="[rules.req]">
                    </v-text-field>
                  </v-col>
                  <v-col cols="6"
                    class="pt-0 mt-0 pb-3 mb-0">
                    <v-text-field dense
                      outlined
                      hide-details
                      class="textField"
                      label="Grad"
                      v-model="company.city"
                      :rules="[rules.req]">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="pt-2 mt-0 pb-2">
                  <v-col cols="6"
                    class="pt-0 mt-0 pb-0 mb-0">
                    <v-text-field dense
                      outlined
                      class="textField"
                      label="Poštanski broj"
                      hide-details
                      v-model="company.zip_code"
                      :rules="[rules.req]">
                    </v-text-field>
                  </v-col>
                  <v-col cols="6"
                    class="pt-0 mt-0 pb-0 mb-0">
                    <v-select class="textField"
                      dense
                      hide-details
                      outlined
                      label="Država"
                      v-model="company.country"
                      :items="countries"
                      item-text="country"
                      item-value="country"
                      :rules="[rules.req]">
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="pt-5 mt-0">
                  <v-col cols="6"
                    class="pt-0">
                    <v-combobox class="textField"
                      outlined
                      autocomplete="off"
                      dense
                      v-model="selectedOibs"
                      :items="[]"
                      label="PDV Id"
                      multiple
                      chips
                      clearable
                      @click="openAddOibForm"
                      @keypress.enter="openAddOibForm"
                      @keydown="preventTextInput('oib', $event)">
                      <template v-slot:selection="{ item }">
                        <v-chip class="mt-1 mr-0"
                          close
                          @click:close="removeOib(item)">
                          <v-avatar>
                            <country-flag :country="item.countryCode ||
                              item.countryCode.toLowerCase()
                              " />
                          </v-avatar>
                          <span class="ml-2">{{ item.oib }}</span>
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col cols="6"
                    class="pt-0">
                    <v-select dense
                      outlined
                      label="Tip firme"
                      :items="companyTypes"
                      v-model="company.type"
                      item-text="name"
                      item-value="value"
                      :rules="[rules.req]"
                      class="companyType"
                      @change="changeCompanyType">
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="pt-0 mt-0">
                  <v-col cols="6"
                    class="pt-0 mt-0">
                    <v-select dense
                      class="textField"
                      :items="taxTypes"
                      v-model="company.tax_type"
                      :label="$t('$vuetify.companies.taxType')"
                      outlined
                      item-text="name"
                      item-value="value">
                    </v-select>
                  </v-col>
                  <v-col class="py-0 pt-0 mt-0"
                    cols="6">
                    <v-checkbox dense
                      outlined
                      class="pt-0 mt-0 textField"
                      label="Firma je u sustavu PDV-a"
                      v-model="company.is_taxed">
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item style="height: 100% !important">
                <v-row class="pt-3"
                  style="text-align: center">
                  <v-col>
                    <v-row class="pb-3 pr-3">
                      <v-spacer></v-spacer>
                      <v-btn v-if="
                        company.bank_accounts &&
                        company.bank_accounts.length > 0
                      "
                        fab
                        class="btn infoButton mb-2"
                        @click="openIbanForm"
                        style="
                          width: 25px;
                          height: 25px;
                          padding: 0px;
                          border-radius: 50%;
                        "><v-icon>mdi-plus</v-icon></v-btn>
                    </v-row>

                    <table v-if="
                      company.bank_accounts &&
                      company.bank_accounts.length > 0
                    "
                      style="width: 100%">
                      <thead>
                        <tr>
                          <th width="30%"
                            colspan="1">IBAN</th>
                          <th width="35%"
                            colspan="1">Naziv banke</th>
                          <th width="20%"
                            colspan="1">Glavni Iban</th>
                          <th width="25%"
                            colspan="1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="bank in company.bank_accounts"
                          :key="bank.id">
                          <td style="text-align: left"
                            colspan="1">
                            {{ bank.iban }}
                          </td>
                          <td colspan="1">{{ bank.bank.name }}</td>
                          <td colspan="1">{{ showMainIban(bank.default) }}</td>
                          <td style="text-align: right"
                            colspan="1">
                            <v-icon style="color: #a9a9a9"
                              class="pr-2"
                              @click="editIban(bank)">mdi-pencil-outline</v-icon>
                            <v-icon style="color: #a9a9a9"
                              @click="removeIban(bank)">mdi-delete-outline</v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>
                <v-row v-if="
                  !company.bank_accounts || company.bank_accounts.length === 0
                "
                  style="justify-content: center; margin-top: 50px">
                  <v-btn class="btn infoButton mb-2"
                    @click="openIbanForm"><v-icon>mdi-plus</v-icon>Dodaj IBAN</v-btn>
                </v-row>
              </v-tab-item>

              <v-tab-item style="height: 100% !important">
                <v-row class="pt-3 pr-3"
                  style="text-align: center">
                  <v-col class="d-flex justify-center pt-0">
                    <v-img style="border: 1px red solid"
                      :src="company.logo"
                      v-model="company.logo"
                      contain
                      max-height="116"
                      max-width="256"
                      ref="image"
                      :lazy-src="require('@/assets/unknown_profile.jpg')"></v-img>
                  </v-col>
                  <v-col class="pt-2 mt-1">
                    <v-file-input class="pt-1"
                      outlined
                      dense
                      style="overflow: hidden"
                      label="Logo"
                      @change="onFileChange"
                      clearable
                      v-model="file"
                      accept=".png, .jpeg, .jpg"
                      @click:close="onFileChange">
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item v-if="company.type === 'doo' || company.type === 'jdoo'"
                style="height: 100% !important">
                <v-row class="pt-7">
                  <v-col cols="6"
                    class="pt-0 pb-0 mb-0 mt-0 pr-0">
                    <v-row class="pt-0 pb-0 mb-0 mt-0">
                      <v-col cols="12"
                        class="pt-0 pb-3 mb-0 mt-0">
                        <v-text-field dense
                          outlined
                          label="Matični broj subjekata"
                          :rules="[rules.req]"
                          v-model="company.legal_info.court_register_id"
                          hide-details>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="pt-0 pb-0 mb-0 mt-0">
                      <v-col cols="12"
                        class="pt-0 pb-0 mb-0 mt-0">
                        <v-select outlined
                          dense
                          hide-details
                          :items="courts"
                          label="Sud"
                          v-model="company.legal_info.court"
                          :rules="[rules.req]">
                        </v-select></v-col></v-row>
                  </v-col>
                  <v-col cols="6"
                    class="pt-0 pb-0 mb-0 mt-0">
                    <v-row class="pt-0 pb-0 mb-0 mt-0">
                      <v-col cols="8"
                        class="pt-0 pb-0 mb-0 mt-0 pr-1">
                        <money-input dense
                          outlined
                          hide-details
                          :currency="company.legal_info.base_capital_currency"
                          label="Temeljni kapital"
                          :rules="[rules.req]"
                          v-model="company.legal_info.base_capital"
                          ref="capital">
                        </money-input>
                      </v-col>
                      <v-col cols="4"
                        class="pt-0 pb-0 mb-0 mt-0 pl-0">
                        <v-select label="Valuta"
                          hide-details
                          outlined
                          dense
                          :items="currencies"
                          item-text="name"
                          item-value="name"
                          v-model="company.legal_info.base_capital_currency">
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class="pt-0 pb-0 mb-0"
                    cols="12">
                    <v-row class="pt-3"
                      style="text-align: center">
                      <v-col>
                        <v-row class="pb-3 pr-3">
                          <v-spacer></v-spacer>
                          <v-btn v-if="
                            company.legal_info &&
                            company.legal_info.members &&
                            company.legal_info.members.length > 0
                          "
                            fab
                            style="
                              width: 25px;
                              height: 25px;
                              padding: 0px;
                              border-radius: 50%;
                            "
                            class="btn infoButton mb-2"
                            @click="openAddBoardMembersForm()"><v-icon>mdi-plus</v-icon></v-btn>
                        </v-row>

                        <table style="width: 100%"
                          v-if="
                            company.legal_info &&
                            company.legal_info.members &&
                            company.legal_info.members.length > 0
                          ">
                          <caption style="font-size: 16px; font-weight: bold">
                            Članovi društva
                          </caption>
                          <thead>
                            <tr>
                              <th width="30%"
                                colspan="1">Ime</th>
                              <th width="35%"
                                colspan="1">Prezime</th>
                              <th width="20%"
                                colspan="1">Direktor</th>
                              <th width="25%"
                                colspan="1"></th>
                            </tr>
                          </thead>
                          <tbody v-if="
                            company &&
                            company.legal_info &&
                            company.legal_info.members
                          ">
                            <tr v-for="(member, index) in company.legal_info
                              .members"
                              :key="member.id">
                              <td colspan="1">{{ member.first_name }}</td>
                              <td colspan="1">{{ member.last_name }}</td>
                              <td colspan="1">
                                {{ showMainIban(member.ceo) }}
                              </td>
                              <td style="text-align: right"
                                colspan="1">
                                <v-icon style="color: #a9a9a9"
                                  class="pr-2"
                                  @click="
                                    openAddBoardMembersForm(member, index)
                                    ">mdi-pencil-outline</v-icon>
                                <v-icon style="color: #a9a9a9"
                                  @click="removeMember(index)">mdi-delete-outline</v-icon>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-if="
                  !company.legal_info ||
                  !company.legal_info.members ||
                  company.legal_info.members.length === 0
                "
                  style="justify-content: center; margin-top: 50px">
                  <v-btn class="btn infoButton mb-2"
                    @click="openAddBoardMembersForm()"><v-icon>mdi-plus</v-icon>Dodaj članove
                    društva</v-btn>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex pt-0 mt-0 pr-4 justify-end">
          <v-btn class="btn dangerButton"
            @click="close"> Odustani </v-btn>
          <v-btn id="success"
            class="btn successButton"
            :loading="submitting"
            type="submit">
            Spremi
          </v-btn>
        </v-card-actions>

        <add-oib ref="addOib"
          @add="onAddOib"> </add-oib>
        <add-iban ref="addIban"
          @add="onAddIban"
          :banks="banks"></add-iban>
        <add-board-members ref="addBoardMembers"
          @add="onAddMember"></add-board-members>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import rules from '@/plugins/rules'
import { df, functions, auth } from '@/plugins/firebase'
import { duck, clone } from '@/plugins/utils'
import AddOib from '@/modules/companies/components/AddOib'
import addIban from '@/modules/companies/components/AddIban'
import CountryFlag from 'vue-country-flag'
import countries from '@/mixins/countries'
import formUtils from '@/mixins/formUtils'
import requestChecker from '@/mixins/requestChecker'
import MoneyInput from '@/components/MoneyInput'
import addBoardMembers from '@/modules/companies/components/AddBoardMembers'
import Company from '@/model/Company'
import company from '@/mixins/company'

export default {
  name: 'AddCompany',
  components: { addIban, AddOib, CountryFlag, MoneyInput, addBoardMembers },
  data: () => ({
    defaultUrl: '',
    visible: false,
    logo: undefined,
    submitting: false,
    company: {},
    initialCompany: {},
    defultCompany: {},
    companyTypes: [],
    isTaxed: true,
    rules: {
      req: rules.req(),
      eq: rules.eq,
      oib: rules.oib(),
      arrReq: rules.arrReq()
    },
    shakeFlag: false,
    selectedOibs: [],
    taxTypes: [],
    defaultTaxTypes: [],
    change: false,
    file: undefined,
    banks: [],
    bank: {},
    ibans: [],
    members: [],
    courts: [],
    currencies: [{ name: 'EUR' }, { name: 'HRK' }],
    currency: 'EUR',
    tab: null,
    tabs: [],
    activeTab: 'Osnovni podaci',
    newCompany: false
  }),
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'confirm', 'confirmClose'],
  mixins: [countries, formUtils, requestChecker, company],
  computed: {
    basicDataValid () {
      if (!this.company.name) {
        return false
      }

      if (!this.company.oib) {
        return false
      }

      if (!this.company.address) {
        return false
      }

      if (!this.company.city) {
        return false
      }

      if (!this.company.country) {
        return false
      }

      if (!this.company.zip_code) {
        return false
      }

      return true
    },
    bankDataValid () {
      if (!this.company.bank_accounts || this.company.bank_accounts.length === 0) {
        return false
      }

      return true
    },
    legalInfoValid () {
      if (this.company.type === 'doo' || this.company.type === 'jdoo') {
        if (!this.company.legal_info.court_register_id) {
          return false
        }

        if (!this.company.legal_info.base_capital) {
          return false
        }

        if (!this.company.legal_info.base_capital_currency) {
          return false
        }

        if (!this.company.legal_info.court) {
          return false
        }

        if (!this.company.legal_info.members || this.company.legal_info.members.length === 0) {
          return false
        }

        return true
      }
      return true
    }

  },
  watch: {
    'company.type' (nv, ov) {
      if (nv !== ov) {
        if (['doo', 'jdoo'].includes(nv)) {
          this.types = clone(this.defaultTaxTypes)
          this.taxTypes = this.types.map((type) => {
            if (type.value !== 'profit') {
              type.disabled = true
            }
            return type
          })
        } else {
          this.taxTypes = clone(this.defaultTaxTypes)
        }
      }
    }
  },
  mounted () {
    this.defaultTaxTypes = [
      { name: this.$t('$vuetify.companies.taxTypes.profit'), value: 'profit' },
      { name: this.$t('$vuetify.companies.taxTypes.income'), value: 'income' }
    ]
  },
  methods: {
    changeCompanyType () {
      this.tabs = []
      if (this.company.type === 'doo' || this.company.type === 'jdoo') {
        this.tabs = [
          { id: 0, name: 'Osnovni podaci', icon: 'mdi-note-edit-outline' },
          { id: 1, name: 'Banka', icon: 'mdi-bank-outline' },
          { id: 2, name: 'Logo', icon: 'mdi-panorama-variant-outline' },
          { id: 3, name: 'Poslovni podaci', icon: 'mdi-office-building-outline' }]

        if (!this.company.legal_info) {
          this.company.legal_info = {
            bank: {},
            base_capital: 0,
            base_capital_currency: 'EUR',
            court: '',
            court_register_id: '',
            members: []
          }
        }
      } else {
        this.tabs = [
          { id: 0, name: 'Osnovni podaci', icon: 'mdi-note-edit-outline' },
          { id: 1, name: 'Banka', icon: 'mdi-bank-outline' },
          { id: 2, name: 'Logo', icon: 'mdi-panorama-variant-outline' }]
      }

      this.$forceUpdate()
    },
    showMainIban (def) {
      switch (def) {
        case true:
          return 'DA'
        case false:
          return 'NE'
      }
    },
    changeTab (tab) {
      this.changeCompanyType()

      if (tab === -1) {
        this.activeTab = 'Osnovni podaci'
      }

      this.activeTab = tab
    },
    openAddBoardMembersForm (existing = null, idx = undefined) {
      this.$refs.addBoardMembers.open(existing, idx)
    },
    removeMember (index) {
      this.company.legal_info.members = this.company.legal_info.members.filter((_, i) => i !== index)
    },
    onAddMember (newMember) {
      if (newMember.idx === 0 || newMember.idx) {
        this.company.legal_info.members[newMember.idx] = newMember
      } else if (this.company.legal_info.members !== undefined && this.company.legal_info.members !== null && this.company.legal_info.members.length > 0) {
        this.company.legal_info.members.push(newMember)
      } else {
        this.company.legal_info.members = []
        this.company.legal_info.members.push(newMember)
      }
      this.$forceUpdate()
    },
    createImage (file) {
      const reader = new FileReader()

      reader.onload = (e) => {
        if (!e.target.result?.includes('data:image')) {
          this.showMsgBox({
            text: 'Nedopušteni tip datoteke. Dozvoljeni tipovi su .jpeg, .jpg i .png.',
            actions: ['cancel'],
            cancelBtnText: 'OK',
            color: 'error'
          })
          this.file = undefined
          return
        }

        if (e.target.result) {
          this.company.logo = e.target.result
          this.$forceUpdate()
        }
      }
      reader.readAsDataURL(file)
    },
    onFileChange (file) {
      if (!file) {
        const defaultUrl = this.defaultUrl
          ? this.defaultUrl
          : require('@/assets/unknown_profile.jpg')
        this.company.logo = defaultUrl
      } else {
        this.createImage(file)
      }
    },
    setFocusOnNext () {
      document.getElementById('associatedOib').focus()
    },
    openAddOibForm () {
      this.$refs.addOib.open()
    },
    onAddOib (item) {
      this.selectedOibs.push(item)
      this.focusOnForm()
    },
    removeOib (oib) {
      this.selectedOibs = this.selectedOibs.filter((o) => o !== oib)
    },
    preventTextInput (form, e) {
      if (e.keyCode !== 9 && e.keyCode !== 27) {
        switch (form) {
          case 'iban':
            this.$refs.addIban.open()
            break
          case 'oib':
            this.$refs.addOib.open()
            break
          default:
            break
        }
      }
    },
    openIbanForm () {
      this.$refs.addIban.open()
    },
    onAddIban (ibanStruct) {
      if (ibanStruct.default === true) {
        this.company.bank_accounts = this.company.bank_accounts.map((item) => {
          item.default = false
          return item
        })
      }
      const iban = ibanStruct.initialIban || ibanStruct.iban
      if (this.company.bank_accounts.some((item) => item.iban === iban)) {
        this.company.bank_accounts = this.company.bank_accounts.map((item) => {
          if (item.iban === iban) {
            return ibanStruct
          } else {
            return item
          }
        })
      } else {
        this.company.bank_accounts.push(ibanStruct)
      }
      this.bank = this.company.bank_accounts.find((item) => item.default) || {}

      this.swift = this.bank.swift
      this.focusOnForm()
    },
    editIban (ibanStruct) {
      this.$refs.addIban.open(ibanStruct)
    },
    removeIban (ibanStruct) {
      this.company.bank_accounts = this.company.bank_accounts.filter(
        (ibanStr) => ibanStr.iban !== ibanStruct.iban
      )
    },
    async open (company = undefined) {
      this.visible = true
      this.submitting = false

      if (company) {
        if (company.legal_info && !company.legal_info.base_capital_currency) {
          company.legal_info.base_capital_currency = 'HRK'
        }
        this.company = clone(company)
        this.initialCompany = clone(company)
        this.newCompany = false
      } else {
        this.company = new Company()
        this.newCompany = true
      }

      this.defaultUrl = this.company.logo || ''
      this.companyTypes = [
        { name: this.$t('$vuetify.companies.types.doo'), value: 'doo' },
        { name: this.$t('$vuetify.companies.types.jdoo'), value: 'jdoo' },
        { name: this.$t('$vuetify.companies.types.crafts'), value: 'obrt' },
        {
          name: this.$t('$vuetify.companies.types.pcrafts'),
          value: 'pausalni_obrt'
        }
      ]

      this.changeCompanyType()

      if (this.company.legal_info?.members) {
        this.members = this.company.legal_info.members
      }

      this.$nextTick(() => {
        if (this.company.associated_oibs) {
          Object.keys(this.company.associated_oibs).forEach((key) => {
            this.selectedOibs.push({
              oib: this.company.associated_oibs[key],
              countryCode: key
            })
          })
        }
        setTimeout(() => {
          this.setFormInitalValue(this.$refs.editCompanyForm)
        }, 50)
      })

      this.banks = await this.getBanks()
      this.courts = await this.getCourts()
      this.setBank(this.company.bank_accounts.find((item) => item.default))
      this.registerRequestParams(this.company)
    },

    async checkDuplicateOib () {
      const query = df
        .collection('companies/')
        .where('oib', '==', this.company.oib)
      let found = false
      await query.get().then((snapshot) => {
        snapshot.forEach((doc) => {
          if (doc.data().oib === this.company.oib) {
            found = true
          }
        })
      })
      return found
    },

    async getBanks () {
      const bankCollection = await df.doc('project_constants/banks').get()
      if (!bankCollection?.data()?.banks) return []
      return Object.values(bankCollection.data().banks)
    },
    async getCourts () {
      const courtsCollection = await df.doc('project_constants/courts').get()
      if (!courtsCollection?.data()?.courts) return []
      return courtsCollection.data().courts
    },
    setBank (defaultBank = undefined) {
      if (!defaultBank) return
      if (!defaultBank.iban || defaultBank.iban.length < 11) return
      const bankPrefix = defaultBank.iban.substr(4, 7)
      const bank = this.banks.find(
        (bank) => bank.leading_bank_num === bankPrefix
      )
      this.bank = { ...defaultBank, bank }
    },
    close () {
      this.$emit('close')
      if (this.isFormDirty(this.$refs.editCompanyForm)) {
        this.confirm({
          title: this.$t('$vuetify.formDetails.leaveFormTitle'),
          message: this.$t('$vuetify.formDetails.leaveFormText'),
          options: {
            toolbar: true,
            width: 410,
            confirmText: this.$t('$vuetify.formDetails.leave'),
            cancelText: this.$t('$vuetify.formDetails.stay')
          }
        }).then((resp) => {
          if (resp) {
            this.visible = false
            this.tabs = []
            this.activeTab = 'Osnovni podaci'
            this.tab = 0
            this.changeTab(-1)
            this.selectedOibs = []
            this.file = undefined
            this.company.logo = this.defaultUrl
            this.confirmClose()
          }
        })
      } else {
        this.$refs.editCompanyForm.reset()
        this.visible = false
        this.tabs = []

        this.activeTab = 'Osnovni podaci'
        this.tab = 0
        this.changeTab(-1)
        this.selectedOibs = []
        this.file = undefined
        this.company.logo = this.defaultUrl
        this.confirmClose()
      }
    },
    // openLegalInfoForm () {
    //   this.$refs.legalInfo.open(this.company.legal_info, this.bank)
    // },
    focusOnForm () {
      this.$refs.oib.focus()
    },

    async submit () {
      const that = this

      if (!this.$refs.editCompanyForm.validate()) { return }

      if (!this.basicDataValid) {
        this.tab = 0
        return
      }

      if (!this.bankDataValid) {
        this.tab = 1
        return
      }

      if (this.company.type === 'jdoo' || this.company.type === 'doo') {
        var defaultFound = false
        this.company.bank_accounts.forEach(bank => {
          if (bank.default) {
            defaultFound = true
            var lgBank = {
              name: bank.bank.name,
              country: bank.bank.country,
              zip_code: bank.bank.zip_code,
              city: bank.bank.city,
              address: bank.bank.street
            }
            this.company.legal_info.bank = lgBank
          }
        })

        if (!defaultFound) {
          this.showMsgBox({ text: 'Nije pronađen glavni IBAN, molimo postavite glavni IBAN kako bi nastavili.', actions: ['ok'], cancelBtnText: 'OK', color: 'error' })
          return
        }
      }

      if ((this.company.type === 'jdoo' || this.company.type === 'doo') && !this.legalInfoValid) {
        this.tab = 3
        return
      }

      if ((this.company.type === 'obrt' || this.company.type === 'pausalni_obrt') && this.company.legal_info) {
        this.company.legal_info = null
      }

      if (duck(this.company, this.initialCompany)) {
        this.showMsgBox({ text: this.$t('$vuetify.errors.noChangesInEntities'), actions: ['ok'], cancelBtnText: 'OK', color: 'error' })
        return
      }

      if (this.checkCompanyIsSameAsInitial(this.initialCompany, this.company)) {
        this.showMsgBox({ text: this.$t('$vuetify.errors.noChangesInEntities'), actions: ['ok'], cancelBtnText: 'OK', color: 'error' })
        return
      }

      if (duck(this.defultCompany, this.company) && !this.logo) return

      if (!this.$refs.editCompanyForm.validate()) {
        return
      }

      if (this.company.type === 'jdoo' || this.company.type === 'doo') {
        var baseCapital = this.company.legal_info.base_capital
        if (typeof (this.company.legal_info.base_capital) === 'string') {
          baseCapital = clone(parseInt(
            this.$refs.capital.currencyToFloat(this.company.legal_info.base_capital) * 100
          ))
        }
      }

      this.submitting = true
      if (
        this.company.iban &&
        this.company.bank_accounts &&
        this.company.bank_accounts.length > 0
      ) {
        this.company.bank_accounts[0].iban = this.company.iban
      }
      try {
        if (this.company.associated_oib) {
          delete this.company.associated_oib
        }
        const oibs = this.selectedOibs.reduce(
          (oibs, it) =>
            Object.assign(oibs, { [it.countryCode.toLowerCase()]: it.oib }),
          {}
        )

        var resource = ''
        var operation = 'update'
        if (this.newCompany) {
          operation = 'set'
          const duplicateOib = await this.checkDuplicateOib()
          if (duplicateOib === true) {
            that.showMsgBox({
              text: 'Oib ' + this.company.oib + ' je već registriran.',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
            that.$refs.oib.reset()
            this.submitting = false
            return
          }
        } else {
          resource = this.company.id
        }

        this.company.associated_oibs = oibs
        const payload = {
          action: {
            operation: operation,
            entity: 'company',
            resource_id: resource,
            params: clone(this.company)
          }
        }

        if (payload.action.params.legal_info?.base_capital) {
          payload.action.params.legal_info.base_capital = baseCapital
        }

        // const cleanParam = this.getCleanParams(this.company)
        // Object.keys(cleanParam).forEach((key) => {
        //   delete payload.action.params[key]
        // })

        if (payload.action.params.logo) {
          if (payload.action.params.logo !== this.defaultUrl) {
            payload.action.params.logo = payload.action.params.logo.split(',')[1]
          }
        }

        if (payload.action.params.active_products) {
          delete payload.action.params.active_products
        }

        if (payload.action.params.warehouse) {
          delete payload.action.params.warehouse
        }

        if (payload.action.params.logo === '') {
          delete payload.action.params.logo
        }

        payload.action.params.user_id = auth.currentUser.uid

        var companyApi = functions.httpsCallable('companyapi')
        that.$emit('editSuccess', that.company)

        companyApi({
          action: {
            operation: operation,
            entity: 'company',
            resource_id: resource,
            params: payload.action.params
          }
        })
          .then((result) => {
            if (result.data.code === 200) {
              that.selectedOibs = []
              that.visible = false
              this.submitting = false
            } else {
              this.submitting = false
              that.selectedOibs = []
              that.showMsgBox({
                text: 'Dogodila se greška',
                actions: ['cancel'],
                cancelBtnText: 'OK',
                color: 'error'
              })
            }
          })
      } catch (err) {
        this.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.submitting = false
      }
    }
  }
}
</script>
<style scoped>
.companyType>>>.v-input__append-outer {
  margin-top: 0 !important;
  margin-left: 0 !important;
}

.v-text-field>>>.v-input__slot {
  border-radius: 10px;
}

.shake-info {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.addIban>>>.v-input__prepend-inner {
  display: inline-block !important;
}

.addIban>>>.v-input__slot {
  display: inline !important;
}

.addIban>>>.v-text-field__slot {
  display: inline-block !important;
}

.v-dialog__content>>>.my-custom-dialog {
  position: absolute;
  top: 50px;
  border-radius: 25px !important;
}

/* .my-input.v-input .v-input__slot {
  border-radius: 100px;
} */
.textField>>>.v-input__slot {
  border-radius: 12px !important;
}

td {
  text-align: left;
}

th {
  text-align: left;
}

.card-company {
  border-radius: 25px !important;
  height: 500px !important;
  overflow-y: hidden;
}
</style>
