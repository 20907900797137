<template>
  <!-- <div style="width: 100%"> -->
  <v-row class="text-h4 primary--text align-center"
    style="overflow: hidden">
    <v-col md="4"
      lg="5"
      class="hidden-md-and-down one-liner-text mb-0 pb-3 flex-grow-1"
      style="min-width: 100px; max-width: 100%">
      <v-btn v-if="!this.onLine"
        @click="showConnectionDialog()"
        class="glow-btn">
        <v-icon color="red"
          v-if="!this.onLine">mdi-wifi-off </v-icon>
      </v-btn>
      {{ title }}
      <v-btn class="white--text rounded-card"
        color="fiscButton"
        @click="routeToReceipts"
        id="routeReceiptsBtn"
        v-if="unfiskReceipts.length !== 0 && !$route.name.includes('receipts')">Broj nefiskaliziranih računa:
        {{ unfiskReceipts.length }}
      </v-btn>
    </v-col>
    <v-col cols="10"
      sm="11"
      md="7"
      lg="6"
      xl="7"
      class="d-flex align-center justify-end">
      <span style="
          font-weight: bold;
          text-transform: capitalize;
          font-size: 16px;
          padding: 0 20px 0 0;
        "></span>
      <span style="
          font-weight: bold;
          text-transform: capitalize;
          font-size: 16px;
          padding: 0 20px 0 0;
        ">{{ user.name + " " + user.surname }}</span>
      <v-btn :style="{
        'max-width': $vuetify.breakpoint.mdAndUp ? '50%' : '100%',
        'font-size': '10px',
      }"
        plain
        class="font-weight-bold"
        outlined
        color="primary"
        @click="logoutCashRegister"
        v-if="showCashRegLogout">
        {{ "Odjavi se s blagajne" }}
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text
            plain
            v-bind="attrs"
            v-on="on"
            @keypress.enter.stop="">
            {{ $t("$vuetify.cashRegister.optionsText") }}
            <v-icon v-if="menu">mdi-menu-up</v-icon>
            <v-icon v-else>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-for="(item, index) in cashRegisterOptions"
            :key="index"
            style="cursor: pointer"
            @click="`${item.action()}`"
            @keypress.enter.prevent="`${item.action()}`">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-list-item style="cursor: pointer; height: 2.8rem; overflow-y: hidden">
            <span
              style="font-size: 0.8125rem; font-weight: 500; line-height: 1rem">{{ `${this.switchEditMode ? "Edit Off" : "Edit On"}` }}</span>
            <v-switch style="margin-left: 5.4rem; margin-right: -1.2rem"
              v-model="switchEditMode"
              @change="onEditModeChange"></v-switch>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <change-template-dialog v-if="templatesDialogOpen"
      @selectTempate="changeCashRegisterTemplate"
      @closeTemplatesDialog="onCloseTemplatesDialog"
      @selectPaymentPanel="changePaymentPanel"></change-template-dialog>
    <v-dialog v-model="connectionDialog"
      persistent
      width="500"
      height="689">
      <v-card class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }">
        <v-card-title class="d-flex"
          style="vertical-align: middle">
          <v-row class="pa-2">
            <h4>Internet veza</h4>
            <v-spacer></v-spacer>
            <v-icon large
              @click="showConnectionDialog">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <h4>Molimo provjerite vezu s internetom.</h4>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import state from '@/state'
import applicationSettings from '@/mixins/applicationSettings'
import { auth, df } from '@/plugins/firebase'
import EventBus from '@/plugins/event-bus'
import ChangeTemplateDialog from '@/components/top-bar/components/ChangeTemplateDialog'
import onScreenKeyboard from '@/mixins/onScreenKeyboard'
import LogData from '@/model/LogData'
import log from '@/mixins/log'

export default {
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  mixins: [applicationSettings, onScreenKeyboard, log],
  components: {
    ChangeTemplateDialog
  },
  data: function (vm) {
    return {
      templatesDialogOpen: false,
      switchEditMode: false,
      switchOnScreenKeyboard: true,
      registerMode: '',
      registerPaymentPanel: '',
      menu: false,
      cashRegisterOptions: [
        { title: vm.$t('$vuetify.cashRegister.selectTemplate'), action: vm.showTemplateDialog }
      ],
      unfiskReceipts: [],
      listeners: [],
      showCashRegLogout: false,
      switchOnOrdersTable: false,
      onLine: true,
      user: undefined,
      connectionDialog: false,
      selectedPrinter: {}
    }
  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
  },
  async created () {
    this.user = state.getCashRegisterUser().id ? state.getCashRegisterUser() : state.getUser()
    this.registerMode = await this.setRegisterTemplate()

    this.selectedPrinter = state.getSelectedPrinter()
  },
  mounted () {
    EventBus.$on('set-selected-printer', (printer) => {
      this.selectedPrinter = printer
      // console.log('this.selectedPrinter', this.selectedPrinter)
    })

    window.addEventListener('online', () => { this.onLine = true })
    window.addEventListener('offline', () => { this.onLine = false })

    if (state.getCurrentCompany().id !== '') {
      let query = df.collection(`receipts/${state.getCurrentCompany().id}/receipts`)
        .orderBy('issue_date_time', 'desc')

      const now = parseInt(
        (new Date().getTime() / 1000).toFixed(0)
      )

      const tmp = new Date()
      tmp.setDate(tmp.getDate() - 2)

      const before = parseInt(
        (tmp.getTime() / 1000).toFixed(0)
      )
      query = query.where('fiscalize', '==', true).where('jir', '==', '').where('issue_date_time', '>=', before).where('issue_date_time', '<=', now)

      const listener = query
        .onSnapshot((doc) => {
          doc.docs.forEach((rec) => {
            this.unfiskReceipts.push(rec.data())
          })
          if (doc.docs.length === 0) {
            this.unfiskReceipts = []
          }
        }
        )

      this.listeners.push(listener)
    }

    const cashReg = state.getCashRegister()
    if (cashReg.login_type !== undefined && cashReg.login_type !== '' && cashReg.login_type !== 'DEFAULT') {
      this.showCashRegLogout = true
    }
  },
  computed: {
    title () {
      return state.getCashRegister().name
    },
    listView () {
      return state.isListView()
    }
  },
  methods: {

    callAction (imeFunkcije) {

    },
    showConnectionDialog () {
      this.connectionDialog = !this.connectionDialog
    },
    onEditModeChange () {
      EventBus.$emit('set-mode', this.switchEditMode)
    },
    onCloseTemplatesDialog () {
      this.templatesDialogOpen = false
    },
    showTemplateDialog () {
      this.templatesDialogOpen = true
    },
    changeCashRegisterTemplate () {
      this.hideKbd()
      if (state.getRegisterType() === 'cash') {
        state.setRegisterType('cash')
        this.registerMode = 'cash'
      } else if (state.getRegisterType() === 'panel') {
        state.setRegisterType('panel')
        this.registerMode = 'panel'
      } else if (state.getRegisterType() === 'ai-caffe-wide') {
        state.setRegisterType('ai-caffe-wide')
        this.registerMode = 'ai-caffe-wide'
      } else if (state.getRegisterType() === 'bottom-up') {
        state.setRegisterType('bottom-up')
        this.registerMode = 'bottom-up'
      } else if (state.getRegisterType() === 'ai-crud') {
        state.setRegisterType('ai-crud')
        this.registerMode = 'ai-crud'
      }
      this.saveRegisterType()
      EventBus.$emit('change-register-template')
    },
    changePaymentPanel () {
      this.hideKbd()
      if (state.getRegisterPaymentPanel() === 'default') {
        state.setRegisterPaymentPanel('simple')
        this.registerPaymentPanel = 'simple'
      } else {
        state.setRegisterPaymentPanel('default')
        this.registerPaymentPanel = 'default'
      }
      this.saveRegisterPaymentPanel()
      EventBus.$emit('change-register-payment-panel')
    },
    async saveRegisterType () {
      const me = this
      df.doc(`cash_registers/${state.getCashRegister().id}`).update({ register_template: this.registerMode })
        .then(() => {
          // console.log(`Register template changed to ${this.registerMode}`)
        })
        .catch(() => {
          me.hideLoader()
          me.showMsgBox({ text: 'Pogreška prilikom promjene templatea blagajne', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        })
    },
    async setRegisterTemplate () {
      const register = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
      if (register && register.data()) {
        const doc = register.data()
        if (doc && doc.register_template) {
          state.setRegisterType(doc.register_template)
        } else {
          state.setRegisterType('cash')
        }
      } else {
        state.setRegisterType('cash')
      }
      return state.getRegisterType()
    },
    async saveRegisterPaymentPanel () {
      const me = this
      df.doc(`cash_registers/${state.getCashRegister().id}`).update({ register_payment_panel: this.registerPaymentPanel })
        .then(() => {
        })
        .catch(() => {
          me.hideLoader()
          me.showMsgBox({ text: 'Pogreška prilikom promjene payment panel-a  blagajne', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        })
    },
    async setRegisterPaymentPanel () {
      const register = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
      if (register && register.data()) {
        const doc = register.data()
        if (doc && doc.register_payment_panel) {
          state.setRegisterPaymentPanel(doc.register_payment_panel)
        } else {
          state.setRegisterPaymentPanel('default')
        }
      } else {
        state.setRegisterPaymentPanel('default')
      }
      return state.getRegisterPaymentPanel()
    },
    async logoutCashRegister () {
      this.showLoader()

      if (state.getCurrentCompany().save_log) {
        this.prepareLogCall()
      }
      // const register = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()

      // const cashRegUser = state.getCashRegisterUser()
      // const cashRegUserName = cashRegUser.name + ' ' + cashRegUser.surname
      // if (register.reserved_by !== '' && register.reserved_by === cashRegUserName) {
      state.setCashRegisterUser({ id: undefined })
      df.doc(`cash_registers/${state.getCashRegister().id}`).update({ reserved_by: '' })
        .then(() => {
          console.log('success')
          this.$router.push({
            name: 'pointOfSale.cashRegisters',
            params: {
              companyId: state.getCurrentCompany().id,
              locationId: state.getPointOfSale().id
            }
          })
        })
        .catch(() => {
          this.hideLoader()
          this.showMsgBox({ text: 'Pogreška prilikom zapisa podataka', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
          this.$router.push({
            name: 'pointOfSale.cashRegisters',
            params: {
              companyId: state.getCurrentCompany().id,
              locationId: state.getPointOfSale().id
            }
          })
        })
        .finally(() => {
          this.hideLoader()
        })
      // }
    },
    prepareLogCall () {
      var req = new LogData()

      let userId = auth.currentUser.uid
      if (state.getCashRegisterUser().id && state.getCashRegisterUser().id !== '') {
        userId = state.getCashRegisterUser().id
      }

      req.action_name = 'Odjava'
      req.action_id = '2'
      req.user_id = userId

      this.setLog(req)
    },
    routeToReceipts () {
      this.$router.push({
        name: 'pointOfSale.receipts',
        params: {
        }
      })
    },
    changeView () {
      state.setListView(!state.isListView())
    },
    createReport () {
      if (!state.events) return
      state.events.createReport = true
    },
    closeBalance () {
      if (!state.events) return
      state.events.closeBalance = true
    }
    // selectTemplate () {
    //   if (state.getRegisterType() === 'cash') {
    //     state.setRegisterType('panel')
    //     this.registerMode = 'panel'
    //   } else {
    //     state.setRegisterType('cash')
    //     this.registerMode = 'cash'
    //   }
    //   EventBus.$emit('change-register-template')
    // }
  }
}
</script>
<style scoped>
.glow-btn {
  box-shadow: 0px 0px 0px 3px red inset;
}

@keyframes glowing {
  0% {
    box-shadow: none;
  }

  50% {
    box-shadow: 0px 0px 0px 3px red inset;
  }

  100% {
    box-shadow: none;
  }
}

.glow-btn {
  animation: glowing 700ms infinite;
  font-weight: 700;
}
</style>
